// here are all the styling variables that you use
// e.g. a hex value for a primary color that is used throughout the whole website

// fonts
$font-montserrat: 'Montserrat', sans-serif;

// colours
$color-primary: #8ae6bc;
$color-white: #fff;
$color-gray: #707070;
$color-red: #CE5445;
$color-darkgray: #605F5F;
$color-black: #231f20;

@mixin screen($size) {
  $desktop: "(min-width: 1024px)";
  $tablet: "(min-width: 768px) and (max-width: 1023px)";
  $mobile: "(max-width: 767px)";

  @if $size==desktop {
    @media only screen and #{$desktop} {
      @content;
    }
  }

  @else if $size==tablet {
    @media only screen and #{$tablet} {
      @content;
    }
  }

  @else if $size==mobile {
    @media only screen and #{$mobile} {
      @content;
    }
  }

  @else {
    @media only screen and #{$size} {
      @content;
    }
  }
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin transform($args...) {
  -webkit-transform: $args;
  -moz-transform: $args;
  -ms-transform: $args;
  -o-transform: $args;
  transform: $args;
}