// navigation styling
$duration: .2s;
$distance: 8px;
$easeOutBack: cubic-bezier(0.175, 0.885, 0.320, 1.275);

.navbar {
    padding: 2em 1em;
    list-style-type: none;
    margin: 0;
    text-align: center;

    li {
        display: inline-block;
        margin: 0 20px;
    }

    .navbar-toggler {
        color: $color-black;
        border: none;
    }
}

.navbar a {
    color: $color-black;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 1em;
    font-weight: 700;
    letter-spacing: 3px;
    position: relative;
    padding-bottom: 8px;

    &:before,
    &:after {
        content: '';
        position: absolute;
        bottom: 2px;
        left: 0;
        right: 0;
        height: 2px;
        background-color: $red;
    }

    &:before {
        opacity: 0;
        transform: translateY(- $distance);
        transition: transform 0s $easeOutBack, opacity 0s;
    }

    &:after {
        opacity: 0;
        transform: translateY($distance/2);
        transition: transform $duration $easeOutBack, opacity $duration;
    }

    &:hover,
    &:focus {
        color: $color-black;

        &:before,
        &:after {
            opacity: 1;
            transform: translateY(0);
        }

        &:before {
            transition: transform $duration $easeOutBack, opacity $duration;
        }

        &:after {
            transition: transform 0s $duration $easeOutBack, opacity 0s $duration;
        }
    }
}

.hamburger {
    width: 30px;
    height: 20px;
    position: relative;
    margin: 0px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

.hamburger span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

.hamburger span {
    background: $color-black;
}

.hamburger span:nth-child(1) {
    top: 0px;
}

.hamburger span:nth-child(2),
.hamburger span:nth-child(3) {
    top: 10px;
}

.hamburger span:nth-child(4) {
    top: 20px;
}

.hamburger.open span:nth-child(1) {
    top: 11px;
    width: 0%;
    left: 50%;
}

.hamburger.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.hamburger.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.hamburger.open span:nth-child(4) {
    top: 11px;
    width: 0%;
    left: 50%;
}