@import "variables";
@import "bootstrap/scss/bootstrap";
@import "text";
@import "nav";

html {
  scroll-behavior: smooth;
}

body a{
  color: $color-red;
  @include transition(all .4s ease);
}

body a:hover{
  color: $color-red;
}

.section-wrapper {
  position: relative;
  width: 100%;
}

.swiper-container {
  width: 600px;
  height: 500px;
}

.hide {
  display: none;
}

.swiper-container {
  width: 100%;
}

.swiper-slide {
  background-position: center;
}

.container-fluid {
  position: relative;
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
}

.logo {
  position: absolute;
  top: 20px;
  left: 10px;
  z-index: 11;
}

.line {
  height: 40vh;
  border-left: 1px solid $color-white;
  padding-left: 5px;
  color: $color-white;
  margin-left: 50px;
  transition: height 0.3s linear;
}

.line.expand {
  height: 50vh;
}

.slogan {  
  font-size: 24px;
  font-weight: bold;
  margin-left: 50px;
}

.slogan p {
  color: $color-white;
  text-align: left;
  margin: 0;
  font-size: 1.2em;
  font-weight: bold;
}

.ellipsis {
  position: absolute;
  left: 20px;
  bottom: 0vh;
  z-index: 11;
  font-size: 2em;
  color: $color-white;
}

.black {
  color: $color-black;
}

.menu {
  position: absolute;
  left: 19%;
  bottom: 0vh;
  z-index: 11;
  background-color: $color-white;
  width: 95.8%;
  padding: 10px 0;
}

.menu .navbar{
  padding: 0;
}

.ml-20 {
  margin-left: 20%;
  transition: margin-left 0.3s linear;
}

.width-80 {
  width: 80%!important;
}

#carouselExampleControls {
  width: 100%;
  transition: margin-left 0.3s linear;
}

.carousel-item {
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.section-title {
  text-transform: UPPERCASE;
  -ms-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  text-align: left;
  position: absolute;
  top: 15vh;
  left: -10%;
  font-size: 1.5em;
  font-weight: 700;
}

.section {
  position: relative;
  margin: 10vh auto!important;
  min-height: 500px;
  padding: 50px 0;
}

.section-content-heading>h3 {
  width: 58%;
  margin: 0 auto;
}

.num small {
  color: $color-black;
  font-size: 0.7em!important;
}

.section ul {
  display: list-item;
  list-style: none;
  position: relative;
  width: 40%;
  margin: 0 auto;
}

.footer-wrapper ul {
  display: list-item;
  list-style: none;
  position: relative;
  width: 100%;
  margin: 0 auto;
  color: $color-white;
}

.footer-wrapper ul li {
  margin: 5px 0;
}

.footer-wrapper a, .footer-wrapper span, .footer-wrapper p {
  color: $color-white;
}

.footer-wrapper a:hover {
  text-decoration: none;
}

.footer-wrapper {
  margin-top: 0 !important;
  margin-bottom: 0vh !important;
  background-color: #000;
  min-height: 300px !important;
  height: 370px !important;
  position: relative;
}

.social-icon {
  font-size: 2em;
}

.section .nav-tabs a {
  display: inline;
  margin: 0 5px;
  text-transform: uppercase;
}

.section .nav-tabs a:focus {
  color:#dc3545;
}

.section hr {
  border-color: #dc3545 !important;
  width: 58%;
  margin: 5px auto;
}

.section .nav-tabs {
  border-bottom: 0;
}

.section .nav-tabs a {
  text-decoration: none;
  color: $color-black;
}

.section .nav-tabs a:focus, .section .nav-tabs a:hover {
  color: #dc3545 !important;
}

.section .nav-tabs .nav-link {  
  background-color: #fff;
  border: none;
}

.section .nav-tabs .nav-link {
  border:none;
}

.nav-link {
  font-size: .8em;
  padding: 0;
}

.content-wrapper {
  width: 100%;
  margin: 0 auto;
}

.content-wrapper nav {
  width: 80%;
  margin: 10px auto;
}

.tab-content>.tab-pane {
  margin: 20px 0;
  padding: 0 9vh;
}

.tab-content {
  margin: 0 auto;
}

.section .carousel-item {
  height: 250px;
  background-position: center;
  background-repeat: no-repeat;
}

.section #carouselExampleControls {
  margin: 20px auto;
}

.section .nav-link.active {
  color: #dc3545 !important;
}

.section .text-intro {
  margin: 0 auto;
}

.tab-pane h4.text-uppercase {
  margin-top: 10px;
}

.slick-current.slick-active img {
  width: 500px!important;
}

.slick-initialized .slick-slide {
  display: block;
  align-items: center;
  margin: 10px 0;
}


.slick-slide img {
  width: 200px!important;
}

.slick-track {
  display: flex!important;
  align-items: center!important;
}

.desc {
  position: relative;
  margin: 0 auto;
}

.m-0 {
  margin: 0;
}

.p-0 {
  padding: auto 0;
}

.slider-section {
  width: 100%;
  position: relative;
}

.mobile {
  display: none;
}

.solution-wrapper ul {
  width: 100%;
  font-size: .9em!important;
  padding-top: 10vh;
}

.solution-wrapper ul li { 
  margin: 5px 0;
  padding: 5px 10px;
}

.solution-wrapper .slogan a {
  position: absolute;
  bottom: 1vh;
  padding: 10px 20px;
  background-color: gray;
  border-radius: 0;
  color: #fff;
  @include transition(background-color .4s ease);
}

.solution-wrapper .slogan a:hover, .solution-wrapper .slogan a:focus {
  text-decoration: none;
  background-color: #dc3545;
}

.sub-section {
  min-height: 500px;
  margin: 20vh auto !important;
}

.portfolio-wrapper ul {
  padding-top: 0;
}

.partner-logo {
  margin-top: 30px;
}

.portfolio-item {
  font-size: 1.2em;
  font-weight: bold;
}

.career-list .btn-link {
  text-decoration: none;
  color: $color-black;
  font-weight: bold;
}

.career-text-bottom p {
  margin: 5px 0;
}

.vision-wrapper {
  padding-right: 0;
} 

.timeline-wrapper .vr { 
  width: 1px;
  background-color: gray;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  padding: 0;
  height: 100%;
}

.story-list .btn-link {
  text-decoration: none;
}

.timeline-element div {
  position: relative;
}

.timeline-wrapper div {
  padding: 10px 20px;
  min-height: 120px;
}

.timeline-element .fa-circle {
  font-size: .7em;
  position: absolute;
  left: -5px;
  top: 15%;
  z-index: 1;
  color: #dc3545;
}

.tomorrow {
  text-align: center;
}

.desktop {
  display: flex;
}

.profile.desktop {
  display: block;
}

.people-odd .people-name{
  background-color: purple;
  padding: 10px 20px;
  color: #fff;  
  margin-left: -10vh;
  z-index: -1;
  position: relative;
  font-size: 1em;
  text-align: center;
  margin-top: 5px;
  font-weight: bold;
}

.people-odd .people-position{
  text-align: left;
}

.rellax.footer-wrapper {
  margin-top: 20vh !important;
  margin-bottom: 0vh !important;
}

.frame{
  position: absolute;
  z-index: -1;
  border: 1px solid #C8C8C8;  
}

.frame-one {
  right: 22%;
  top: -10%;
  width: 67%;
  height: 70%;
}

.frame-two {
  bottom: -15%;
  left: -5%;
  width: 79%;
  height: 90%;
}

.services-wrapper.sub-section {
  margin: 0vh auto !important;
}

#nv-scrollup {
  $size: 40px;
  display: none;
  background-color: lighten($color-gray, 30%);
  padding: 10px;
  color: $color-white;
  height: $size;
  width: $size;
  position: fixed;
  right: 30px;
  bottom: 30px;
  text-align: center;
  cursor: pointer;
  @include transition(all .5s ease);

  &:hover {
    background-color: $color-red;
    @include transform(translate(0, -10px));
  }
}

.border-left {
  border-left: 1px solid gray;
}

.product-desc {
  padding: 15vh 3vh;
}

.product-image {
  padding:15vh;
  padding-left: 0;
}

@media screen and (min-width: 1600px) {
  .product-desc, .product-image {
    padding: 15vh 10vh;
  }  
}

@media screen and (min-width: 1900px) {
  .product-desc, .product-image {
    padding: 15vh 10vh;
  }
}

.portfolio-list-wrapper {
  position: absolute; right: 0; font-size: 1.5em; width: 30%; z-index: 1;
}

.portfolio-item a {
  text-decoration: none;
  color: $color-black;
  width: 100%;
}

.pic-frame {
  position:relative;width: 95%;height: auto; margin: 0 auto;
}

.milestone-logo {
  text-align: right;
}

.milestone-logo img{
  margin-top: -50px;
}

.our-story ul{
  font-size: 1em!important;
  text-align: left;  
}

.navbar.desktop {
  padding: .7em 2em;
  background-color: $color-white;  
}

.navbar.desktop {
  -webkit-transition: top 0.3s ease-out;
  -moz-transition: top 0.3s ease-out;
  -o-transition: top 0.3s ease-out;
  transition: top 0.3s ease-out;
}

.navbar.desktop .header-wrapper {
  width:20%;float:left;
}

.navbar.desktop #navbarNav {
  position: relative;
  width: 100%;
  float: left;
  padding: 1em .5em;
}

.navbar.desktop .header-wrapper > a:hover:before, .navbar.desktop .header-wrapper > a:hover:after {  
  background-color: transparent;
}

.navbar.desktop .header-wrapper > a:before, .navbar.desktop .header-wrapper > a:after {  
  background-color: transparent;
}

.slider-section .slogan {
  position: absolute;
  bottom: 20%;
  left: 0;
}

.slider-section .slogan h2 {
  color: #fff;
  letter-spacing: .5em;
  font-size: 3em;
}

.logo-wrapper {
  left: 1%;
  z-index: 1025;
  top: 0;
}

.navbar.desktop ul {
  margin: 0 auto;
}

.p-frame {
  position: absolute;
  border: 1px solid gray;
  z-index: -1;
  height: 150px;
}

.p-frame-one {
  top: 10%;
  left: -7%;
  width: 100%;
  height: 200px;
}

.p-frame-two {
  right: 0;
  top: -15%;
  width: 95%;
}

.p-frame-three {
  right: 0;
  width: 95%;
  bottom: -15%;
}

.p-image {
  width: 400px;
  height: 250px;
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
}

.image-right {

  .product-image {
    position: relative;
    height: 350px;
    margin: 10vh 0vh !important;
    padding-left: 5vh!important;
  }
  
  .p-frame {
    height: 140px;
  }
  
  .p-frame-one {
    top: 10%;
    left: 0%;
    width: 95%;
    height: 200px;
  }
  
  .p-frame-two {
    right: -5%;
    top: -10%;
    width: 95%;
  }
  
  .p-frame-three {
    right: -5%;
    width: 95%;
    bottom: -10%;
  }
  
  .p-image {
    background-position: right;   
  }
}

.section:last-child {
  margin-bottom: 0!important;
}


#our-people-wrapper {
  margin-bottom: 0!important;
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
  .section-title {
    left: -10%;
    font-size: 1em;
  }
}


@media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
  .section-title {
    left: -10%;
    font-size: 1em;
  }
}

@media (max-width: 767px) {

  .container-fluid.section.event-wrapper, .container-fluid.section.platform-wrapper, .container-fluid.section.infra-wrapper {
    padding-left: 5%;
  }

  .section .carousel-item {
    height: auto!important;
  }  

  .partner-logo img{
    width: 50px!important;
  }

  .milestone-logo {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .milestone-logo img{
    margin-top: 0px;
    margin: 0 auto;
  }

  #milestone-wrapper .mt-5 {
    margin-top: 0!important;
  }

  .timeline-wrapper .vr {
    right: -1px;
    z-index: -1;
  }

  .portfolio-list-wrapper {
    position: relative;
    width: 100%;
  }

  .portfolio-list-wrapper ul {
    width: 100%;
  }

  .portfolio-list-wrapper  ul li{
    display: inline-block;
  }

  .portfolio-list-wrapper  ul li .mobile{
    width: 30px;
    text-align: center;
  }

  .portfolio-list-wrapper ul li img {
    display: none;
  }

  .desktop {
    display: none;
  }

  .profile.desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .navbar-light.mobile {
    min-height: 60px;
    display: block;
    background-color: #fff;
    width: 100%;
    padding: 10px 20px;
    box-shadow: 1px 2px 2px 1px rgba(0,0,0,.2);
    border-bottom: 1px solid #ececec;
  }

  .hamburger {
    font-size: 1.5em;
  }

  #navbarNav {
    position: absolute!important;
    width: 100%;
  }

  #navbarNav .navbar-nav {
    width: 100%;
    text-align: center;
  }

  #navbarNav .navbar-nav .nav-item {
    display: inline-block;
    margin: 5px 20px;
    padding: 10px;
    text-align: left
  }

  .content-wrapper {
    width:100%;
  }
  
  .section-title {
    left: -29%;
    top: 5%;
    font-size: 1.2em;
  }
  
  .p-name {
    display: none;
  }

  .content-wrapper nav {
    width: 60%;
    margin: 0 auto;
  }

  .section hr {
    margin: 5px auto;
    width: 85%;
    margin-left:12%;
  }

  .section-content-heading>h3{
    width: 80%;
    margin: 0 auto;
  }

  .section nav a small {
    border: 1px solid;
    border-radius: 4px;
    padding: 2px;
    font-size: .7em;
  }

  .section .nav-tabs a {
    margin: 0 2px;
  }

  .section .text-intro {
    margin-left: 0;
    width: 75%;
    margin-top: 10px;
    padding: 0px 15px;
  }

  .card-body {
    padding: 1.25rem 1.5rem;
  }

  .section {    
    margin: 50px auto!important;
  }

  .m-0 {
    margin: 0 15px!important;
  }

  .p-0 {
    padding: 0 15px!important;
  }

  .logo {
    display: none;
  }

  .ellipsis {
    display: none;
  }

  .solution-wrapper ul {
    font-size: .9em!important;
  }

  .solution-wrapper .slogan {
    position: relative;
  }

  .solution-wrapper .slogan h3 { 
    margin-bottom: 25px;
  }

  .portfolio-wrapper.sub-section {
    margin: 0vh auto !important;
  }

  .solution-wrapper ul.text-left {
    padding: 0;
    margin: 0 -20px;
  }

  .solution-wrapper .slogan a {
    position: relative;
    margin: 20px 0;
    font-size: 0.7em;
    bottom: 0;
    background-color: #F84F37;
  }

  .solution-wrapper .section-title {
    left: -28%;
    top: 5%;
  }

  .career-wrapper {
    padding: 10px 20px;
    padding-left: 5%!important;
  }

  .career-wrapper .section-title {
    top: 0%;
    left: -29%;
  }

  .career-wrapper .slogan img {
    width: 100%;
    height: auto;
  }

  .career-wrapper .slogan h2 {
    font-size: 1.7rem;
    letter-spacing: 2px;
  }

  .join-the-team {
    text-align: right;
    letter-spacing: 2px;
  }

  .vision-wrapper{
    text-align: left!important;
    padding: 1.25rem 2.5rem;
  }

  .our-story-wrapper {
    margin: 20vh auto !important;
    padding: 10px 20px;
    padding-left: 5%!important;
  }

  .our-story-wrapper .section-title {
    top: 3.5%;
    left: -28%;
  }

  .timeline-element {
    padding: 10px 20px;
  }

  .timeline-element div {
    padding: 10px 20px;
    height: 120px;
  }

  .timeline-element .fa-circle {
    right: -10%;
    top: 15%;
  }

  .tomorrow {
    text-align: left;
  }
  .container-fluid.section {
    margin: 10vh auto !important;
    padding: 10px 20px;
    padding-left: 10%;
  }

  #content-wrapper, #event-wrapper, #platform-wrapper, #infra-wrapper {
    margin: 10vh auto !important;
    padding: 0!important;
    // padding-left: 5%;
  }

  #content-wrapper hr {
    margin-left: 17%;
    width: 65%;
  }

  #content-wrapper h3, #content-wrapper p {
    padding: 0 7%!important;
  } 
  
  .container-fluid.section.solution-wrapper {
    padding-left: 5%;    
  }

  .slogan {
    margin-top: 3rem !important;
    margin-left: 0;
  }

  .job-accounce h4{
    font-size: .9em;
  }

  .job-accounce h3 {
    font-size: 1em;
    letter-spacing: 1px;
    line-height: 2;
  }

  .social-wrapper.sub-section .content-wrapper {
    text-align: left!important;
  }

  .social-wrapper.sub-section h3{
    font-size: 1.5em;
  }

  .social-wrapper.sub-section img {
    width: 100%;
    height: auto;
  }

  .social-wrapper.sub-section .job-accounce {
    text-align: right;
  }

  .milestone-wrapper .timeline-wrapper {
    font-size: .9em;
  }

  .our-people-wrapper img {
    width: 100%;
    height: auto;
  }

  .our-people-wrapper .frame-one {
    right: -20px;
    top: -5%;
    width: 100%;
    height: 90%;
  }

  .our-people-wrapper .frame-two {
    bottom: -16%;
    left: -15px;
    width: 110%;
    height: 100%;
  }

  .our-people-wrapper .col-md-9 {
    margin: 50px 0;
  }

  .our-people-wrapper .offset-md-2 {
    padding: 0 20px!important;
  }

  .rellax.footer-wrapper, .footer-wrapper.p-5, .services-wrapper.sub-section {
    height: auto!important;
    padding:0!important;
    margin-bottom: 0px!important;
  }

  .services-wrapper.sub-section {
    height: auto!important;
  }

  .people-desc {
    display: none;
  }

  .what-we-do.text-intro{
    padding: 1em 3.5em;
  }

  .what-we-do.tab-content .product-desc {
    padding: 1em 2em!important;
  }

  .product-desc {
    padding: 1em 2.5em!important;
  }

  #infraCarousel .product-desc {
    padding: 1em 1.5em!important;
  }

  .product-desc h3, .product-desc p { 
    padding-left: 5px;
  }

  #infraCarousel .carousel-control-prev {
    left: -10px;
  }

  #eventCarousel .carousel-control-prev {
    left: -10px;
  }
  
  #eventCarousel .carousel-control-next, #infraCarousel .carousel-control-next {
    right: -10px;
  }

  #platformCarousel .product-desc {
    padding: 1em 3.2em !important;
  }

  #infraCarousel .product-desc {
    padding: 1em 2.3em !important;
  }

  #infraCarousel .carousel-control-left {
    right: -12px;
  }

  .carousel-control-prev, .carousel-control-next  {    
    color:#000000;
  }

  .event-wrapper .text-intro {
    padding: 2em!important;
  }

  .infra-wrapper h3.text-uppercase {
    font-size: 1.2em;
  }

  .our-story.nav.nav-tabs {
    margin: 0;
    padding: 0;
  }
  
  .our-story p:first-child {
    line-height: 2;
  }

  .fb-wrapper {
    padding-left: 0px;
  }

  .ig-wrapper {
    padding-left: 0px;
  }

  .info-wrapper {
    padding-right: 0px;
  }

  .profile-wrapper {
    text-align: left;
    margin-top: 10px;
    margin-left: 35px;
  }

  .profile-wrapper .btn-link{
    text-decoration: none;
  }

  .profile-wrapper .btn-link:active, .profile-wrapper .btn-link:focus{
    text-decoration: none;
  }

  .profile-wrapper .people-name{
    margin-bottom: 0;
    font-size: 1em;
  }

  .profile {
    position: relative;
  }

  .navbar-light .navbar-nav .show>.nav-link, .navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link {
    color: $color-white;
  }

  ul.sub-menu {
    position: relative;
  }

  ul.sub-menu li { 
    font-size: 1em;
    margin: 5px 0;
    display: block;
  }

  ul.sub-menu i { 
    font-size: .8em;
  }

  .sub-menu a.nav-link, .sub-menu a.nav-link:focus {
    text-decoration: none;
    font-size: .8em;
    text-transform: uppercase;
    color: #605F5F!important;
  }

  .mobile .navbar-nav {
    background: #000; color: #fff;height: 90vh;padding: 0; padding-top: 3em; overflow-y: scroll;
  }

  .menu-footer {
    padding: 10px 20px;background-color: #000;color: #fff;
  }

  .btn-close {
    font-size:3em;color: #CE5445!important;border: 0;float: right; width: 50%; position: absolute; right: 10px; width: 50px;
  }

  .header-wrapper {
    padding: 10px 20px;height:60px;
  }

  .navbar-light.mobile {
    padding:0;
  }

  .section-wrapper {
    padding: 1vh 0px;
  }

  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: #fff;
  }

  .slider-section .slogan {
    text-align: left;
    width: 100%;
    bottom: 10%;
    left:5%;
  }

  .slider-section .slogan h2{
    font-size: 1.8em;
    letter-spacing: 5px;
  }

  .menu-contact a {
    font-size: .8em!important;
    color: $color-white;
  }

  .tab-content>.tab-pane {
    padding: 0!important;
  }

  .partner-wrapper.sub-section {
    min-height: 250px;
    margin: 5vh 0!important;
  }

  #productCarousel .carousel-item {
    height: 1100px!important;
  }

  #platformCarousel .carousel-item, #eventCarousel .carousel-item {
    height: 700px!important;
  }

  #infraCarousel .carousel-item {
    height: 600px !important;
  }

  .img-placeholder {
    width: 100%;
    height: 279px;
  }
}

.social-wrapper.sub-section {
  min-height: 500px;
  margin: 25vh auto !important;
  margin-top: 15vh!important;
}

.photos {
  position: relative;
  width: 100%;
  height: 500px;
}

.photos img {
  position: absolute;
}

.photos img:nth-of-type(1) {
  top: 10%;
  left: 10%;
  z-index: 11;
  width: 53%;
}

.photos img:nth-of-type(2) {
  left: 0;
  top: 17%;
  height: 50%;
}

.photos img:nth-of-type(3) {
  right: 30%;
  top: 5%;
  height: 30%;
  width: 50%;
}

.photos img:nth-of-type(4) {
  right: 30%;
  top: 40%;
  height: 50%;
  width: 55%;
}

.csr-wrapper.photos {
  height: 350px;
}

.csr-wrapper.photos img:nth-of-type(1) {
  top: 10%;
  left: 20%;
  z-index: 11;
  width: 100%;
  height: auto;
}

.csr-wrapper.photos img:nth-of-type(2) {
  left: 10%;
  top: -4%;
  height: auto;
  width: 80%;
}

.csr-wrapper.photos img:nth-of-type(3) {
  left: 50%;
  top: 50%;
  width: 80%;
  height: auto;
}

.csr-desc {
  padding: 10vh 5vh!important;
}

.csr-img {
  padding: 10vh 5vh!important;
}

.photos img {
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

.our-people-wrapper img[data-src], #solution img[data-src], #career img[data-src], .footer-wrapper img[data-src]{  
  opacity: 0;
}
 
.our-people-wrapper img.loaded, .our-people-wrapper img.error, #solution img.loaded, #solution img.error, #career img.loaded, #career img.error, .footer-wrapper img.loaded, .footer-wrapper img.error {
  -webkit-transition: opacity .5s linear 0.2s;
  -moz-transition: opacity .5s linear 0.2s;
  transition: opacity .5s linear 0.2s;
  opacity: 1;
}

.our-people-wrapper img:not([src]), #solution img:not([src]), #career img:not([src]), .footer-wrapper img:not([src]) {
  visibility: hidden;
}

@media screen and (max-width: 1600px) {
  .csr-img {
    padding: 15vh 5vh!important;
  }

  .csr-wrapper.photos img:nth-of-type(1) {
    width: 140%!important;
  }

  .csr-wrapper.photos img:nth-of-type(2) {
    width: 120%!important;
  }

  .csr-wrapper.photos img:nth-of-type(3) {
    width: 120%!important;
  }
}

@media screen and (min-width: 1900px) {
  .p-image {
    width: 800px;
    height: 500px;
  }

  .p-frame-one {
    height: 80%;
  }

  .p-frame-two, .p-frame-three {
    height: 60%;
  }

  .image-right {
    .product-image {
      width: 800px;
      height: 500px;
    }
  
    .p-frame-one {
      height: 80%;
    }
  
    .p-frame-two, .p-frame-three {
      height: 60%;
    } 
  }

  .csr-wrapper.photos img:nth-of-type(1) {
    width: 85%;
    left: 15%;
  }

  .csr-wrapper.photos img:nth-of-type(3) {
    left: 25%;
  }
}

@media screen and (min-width: 1900px) and (max-width: 3000px) {
  .p-image {
    width: 400px;
    height: 250px;
  }
}

@media screen and (min-width: 1500px) {
  .p-image {
    width: 400px;
    height: 250px;
  }
}

.portfolio-nav-tab {
  width: 250px !important;
}

#portfolio-back-btn {
  font-size: 0.5em;
}

.portfolio-wrapper .portfolio-item img {
  width: 20px;
}
.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
  color: #000000;
}

.product-description {
  font-size: 0.85em;
}

.my-5.d-none {
  margin-top: 0em!important;
  font-size: 1.5em;
}

.mb-4, .my-4 {
  margin-top:1.3rem!important;
  margin-bottom:1.3rem!important;
}

.profile-wrapper {
  position:relative;
}

.pf-action {
  position:absolute;top:20px;left: 0;
}
