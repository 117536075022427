/* montserrat-regular - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/montserrat-v14-latin-regular.eot');
    /* IE9 Compat Modes */
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
        url('../fonts/montserrat-v14-latin-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/montserrat-v14-latin-regular.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../fonts/montserrat-v14-latin-regular.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/montserrat-v14-latin-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/montserrat-v14-latin-regular.svg#Montserrat') format('svg');
    /* Legacy iOS */
}

/* montserrat-700 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/montserrat-v14-latin-700.eot');
    /* IE9 Compat Modes */
    src: local('Montserrat Bold'), local('Montserrat-Bold'),
        url('../fonts/montserrat-v14-latin-700.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/montserrat-v14-latin-700.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../fonts/montserrat-v14-latin-700.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/montserrat-v14-latin-700.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/montserrat-v14-latin-700.svg#Montserrat') format('svg');
    /* Legacy iOS */
}

body {
    font-family: $font-montserrat;
    font-weight: 300
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-montserrat;
    font-weight: 700;
    color: $color-black;
    text-transform: uppercase;
    letter-spacing: 5px;
}

p {
    font-family: $font-montserrat;
    font-size: 1em;
    color: $color-black;
    line-height: 2;
}

a.sm-link {
    font-size: 0.8em;
}